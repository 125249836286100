import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import tw from 'tailwind.macro'
import Button from '../components/button'
import { ContainerLg } from '../components/container'
import { PreventionFooter } from '../components/footer'
import { ReactComponent as SvgArrowRight } from '../components/icons/arrow-right.svg'
import { ReactComponent as LogoWhatsappSvg } from '../components/icons/logo-whatsapp.svg'
import Layout from '../components/layout'
import News from '../components/news'
import SanityContentRenderer from '../components/sanity-content-renderer'
import SEO from '../components/seo'
import { Slideshow } from '../components/slideshow'
import { owl } from '../styles'
import { getSanityImage } from '../util'

const SlideshowWrapper = styled.div`
  ${tw`mb-6 lg:mb-10`};
`

const Grid = styled.div`
  margin: -0.75rem;
  ${tw`flex flex-wrap`};
`

const GridCell = styled.div`
  ${tw`p-3`};
`

const GridCellLarge = styled(GridCell)`
  ${tw`w-full lg:w-2/3`};
`

const GridCellSmall = styled(GridCell)`
  ${tw`w-full lg:w-1/3`};
`

const RichTextBlock = styled(GridCellLarge)`
  ${tw`m-auto lg:my-12`}
`

const InfoCardRoot = styled(Link, {
  shouldForwardProp: prop => prop !== 'isInsect'
})`
  ${tw`relative block no-underline`}
  ${props => (props.isInsect ? tw`bg-insect-tint` : tw`bg-mushroom-tint`)};
  transition: opacity 0.2s;

  &:hover {
    ${tw`opacity-90`}
  }
`

const InfoCardTitle = styled.p`
  ${tw`flex items-center justify-end m-0 p-2 text-lg font-bold text-white`};
`

const InfoCardText = styled.div`
  top: 50%;
  transform: translateY(-50%);
  ${tw`block absolute pin-l w-2/5 text-white text-lg p-8 opacity-90 hidden lg:block`}
  ${props => (props.isInsect ? tw`bg-insect-tint` : tw`bg-mushroom-tint`)};
`

const MushroomDivider = styled.div`
  ${tw`h-px bg-primary w-100 my-6`};
`

const Heading = styled.h2`
  ${tw`text-primary text-xl m-0`};
`

const List = styled.ul`
  ${tw`m-0 list-reset text-lg text-primary`}
  ${owl(tw`mt-2`)}
`

const NewsHeader = styled.h2`
  ${tw`text-3xl text-primary m-0 mb-4`};
`

function RequestAdviceCard() {
  return (
    <div
      css={css`
        ${tw`h-full border border-solid border-primary p-8 flex flex-col text-center justify-around`}
      `}
    >
      <Heading>Ihre kostenlose Beratung</Heading>
      <MushroomDivider />
      <List>
        <li>Analyse vor Ort</li>
        <li>Genaue Orientierung über den Zustand des Gebäudes</li>
        <li>Unverbindliche Offerte</li>
      </List>
      <MushroomDivider />
      <Button as={Link} variant="primary" to="/kontakt">
        Beratung anfordern
      </Button>
    </div>
  )
}

function InfoCard({ title, text, asset, isInsect }) {
  return (
    <InfoCardRoot
      isInsect={isInsect}
      to={`/info/${isInsect ? 'insekten' : 'pilze'}`}
    >
      <Img fluid={asset.fluid} />
      <InfoCardTitle>
        <span
          css={css`
            ${tw`mr-2`}
          `}
        >
          {title}
        </span>
        <SvgArrowRight />
      </InfoCardTitle>
      <InfoCardText isInsect={isInsect}>{text}</InfoCardText>
    </InfoCardRoot>
  )
}

function IndexPage({ data }) {
  const page = data.page.edges[0].node

  const resolveImages = contentBlocks => {
    return {
      ...contentBlocks,
      images: contentBlocks.images.map(imageExtended => {
        return {
          image: getSanityImage(imageExtended.image, {
            maxWidth: 700,
            maxHeight: 400
          })
        }
      })
    }
  }

  const slides = data.slideshow.edges[0].node.slides
  const richText = page._rawContentBlocks[0]
  const insectInfo = resolveImages(page._rawContentBlocks[1])
  const mushroomInfo = resolveImages(page._rawContentBlocks[2])

  const tel = data.companyInfo.tel.replace(/\+|\s/g, '')

  return (
    <Layout FooterComponent={PreventionFooter}>
      <SEO title={page.suffix} description={page.description} />
      <SlideshowWrapper>
        <Slideshow
          slides={slides}
          slideFootnote={
            <div
              css={css`
                ${tw`pt-3`}
                ${owl(tw`ml-2`)}
              `}
            >
              <Button as={Link} to="/kontakt" variant="secondary">
                Beratung anfordern
              </Button>
              <Button as="a" href={`https://wa.me/${tel}`} variant="secondary">
                <LogoWhatsappSvg />
              </Button>
            </div>
          }
        />
      </SlideshowWrapper>
      <ContainerLg>
        <Grid>
          <GridCellLarge>
            <InfoCard
              title={insectInfo.title}
              text={insectInfo.subtitle}
              isInsect={true}
              asset={insectInfo.images[0].image.asset}
            />
          </GridCellLarge>

          <GridCellSmall>
            <RequestAdviceCard />
          </GridCellSmall>

          <GridCellLarge>
            <InfoCard
              title={mushroomInfo.title}
              text={mushroomInfo.subtitle}
              isInsect={false}
              asset={mushroomInfo.images[0].image.asset}
            />
          </GridCellLarge>

          <GridCellSmall />

          <RichTextBlock>
            <SanityContentRenderer blocks={richText.content} />
          </RichTextBlock>
        </Grid>

        <div
          css={css`
            ${tw`mt-16`}
          `}
        >
          <NewsHeader>Aktuelles</NewsHeader>
          <News limit={3} />
          <div
            css={css`
              ${tw`text-center`}
            `}
          >
            <Button as={Link} to="/news" variant="transparent">
              Alle anzeigen
            </Button>
          </div>
        </div>
      </ContainerLg>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    companyInfo: sanityCompanyInfo {
      tel
    }

    page: allSanityPage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          suffix
          description
          _rawContentBlocks
        }
      }
    }

    slideshow: allSanitySlideshow {
      edges {
        node {
          slides {
            title
            image {
              caption
              asset {
                fluid(maxWidth: 1200, maxHeight: 900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            textPosition
            description {
              _key
              _type
              style
              children {
                _key
                _type
                text
              }
            }
          }
        }
      }
    }
  }
`
